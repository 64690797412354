import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Button,
	Stack,
	Fade,
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	Input,
	Text,
	Spinner,
	Center,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import error from '../static/error.svg';
import { FaBook, FaProjectDiagram, FaGithub } from 'react-icons/fa';
import { CourseTree } from '../helpers/treeHelpers.js';
import { TypeAnimation } from 'react-type-animation';
import { Link as RouteLink, useLocation, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

const BASE_URL =
	'https://gt-prereq-visualizer-backend.netlify.app/.netlify/functions/api';

const LoadingComponent = () => {
	return (
		<Center h="80vh">
			<Spinner
				thickness="4px"
				speed="0.65s"
				emptyColor="gray.100"
				color="teal.100"
				size="xl"
			/>
		</Center>
	);
};

function InvalidCourseComponent({ courseName }) {
	return (
		<Flex
			direction="column"
			align="center"
			justifyContent="flex-start"
			minH="80vh"
		>
			<Image src={error} alt="Error" width="50%" />
			<Heading fontSize="3xl" textAlign="center" pb={4}>
				Invalid Course
			</Heading>
			<Text fontSize="xl" textAlign="center">
				{courseName} is not a valid course or does not have any prerequisites.
			</Text>
		</Flex>
	);
}

function CourseInputForm({
	setCourseName,
	setNodes,
	setLinks,
	setValid,
	setLoading,
}) {
	function validateName(value) {
		let error;
		if (!value) {
			error = 'A course name is required.';
		}
		return error;
	}
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission
			event.target.blur(); // Remove focus from the input field
		}
	};
	// called when the course is submitted
	const handleFormSubmission = (values, actions) => {
		// enable the loading component (spinner)
		setLoading(true);
		setTimeout(async () => {
			try {
				setCourseName(values.course);
				const response = await fetch(
					`${BASE_URL}/get-tree?course=${values.course}`
				);
				const data = await response.json();
				if (!data.error) {
					// if there are no links or nodes, then the course does not have prerequisites
					if (data.nodes.length === 0 || data.links.length === 0) {
						setValid(false);
					} else {
						setValid(true);
					}
					setNodes(data.nodes);
					setLinks(data.links);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
				actions.setSubmitting(false);
			}
		}, 500);
	};
	return (
		<Flex width="full" align="center" justifyContent="center">
			<Formik
				initialValues={{ course: 'CS 1332' }}
				onSubmit={handleFormSubmission}
			>
				{(props) => (
					<Box
						border="2px"
						borderColor="gray.100"
						padding="20px"
						borderRadius="20px"
					>
						<Form direction="column" align="center" mx="auto">
							<Field
								name="course"
								validate={validateName}
								onKeyDown={handleKeyDown}
							>
								{({ field, form }) => (
									<FormControl
										isInvalid={form.errors.course && form.touched.course}
									>
										<FormLabel>Course Name</FormLabel>
										<Input {...field} placeholder="Course Name" />
										<FormErrorMessage>{form.errors.course}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							<Button
								mt={4}
								bg="teal.100"
								isLoading={props.isSubmitting}
								type="submit"
							>
								Search
							</Button>
						</Form>
					</Box>
				)}
			</Formik>
		</Flex>
	);
}

export default function TreeVisualizer() {
	const [courseName, setCourseName] = useState('');
	const [nodes, setNodes] = useState([]);
	const [links, setLinks] = useState([]);
	const [isValid, setValid] = useState(true);
	const [isLoading, setLoading] = useState(false);
	return (
		<Box>
			<Fade in={true}>
				<Flex
					py={{ base: 4 }}
					px={{ base: 4 }}
					direction={{ base: 'column', md: 'column' }}
					justify="flex-start"
					align="center"
					py={10}
					minH={{ base: '300px', md: '400px' }}
				>
					<Heading
						fontSize={{ base: '3xl', md: '4xl' }}
						mb={6}
						width="100%"
						textAlign="center"
					>
						Georgia Tech Prerequisite Visualizer
					</Heading>
					<CourseInputForm
						setCourseName={setCourseName}
						setNodes={setNodes}
						setLinks={setLinks}
						setValid={setValid}
						setLoading={setLoading}
					/>
					{isLoading ? (
						<LoadingComponent />
					) : isValid ? (
						<CourseTree courseName={courseName} nodes={nodes} links={links} />
					) : (
						<InvalidCourseComponent courseName={courseName} />
					)}
				</Flex>
			</Fade>
		</Box>
	);
}
