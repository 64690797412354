import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Text,
	IconButton,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	Stack,
	Collapse,
	Link,
	useBreakpointValue,
	useDisclosure,
	Fade,
	Center,
	ScaleFade,
	Slide,
	SlideFade,
	Highlight,
	UnorderedList,
	Divider,
	ListItem,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import postsData from "../static/posts";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MarkdownComponent from "./MarkdownComponent";

function titleToUrl(title) {
	return title
		.toLowerCase() // Convert to lowercase
		.trim() // Remove leading and trailing spaces
		.replace(/\s+/g, "-") // Replace spaces with hyphens
		.replace(/[^\w\-]+/g, "") // Remove all non-word characters
		.replace(/\-\-+/g, "-"); // Replace multiple hyphens with a single hyphen
}

function parseDate(dateString) {
	const date = new Date(dateString);

	const options = { year: "numeric", month: "long", day: "numeric" };
	const formattedDate = date.toLocaleDateString("en-US", options);

	const day = date.getDate();
	let daySuffix = "th";

	if (day % 10 === 1 && day !== 11) {
		daySuffix = "st";
	} else if (day % 10 === 2 && day !== 12) {
		daySuffix = "nd";
	} else if (day % 10 === 3 && day !== 13) {
		daySuffix = "rd";
	}

	return formattedDate.replace(/\d+/, `${day}${daySuffix}`);
}

const BlogPost = () => {
	const [post, setPost] = useState(null);
	const { title } = useParams(); // This captures the :title part of the URL
	const [markdown, setMarkdown] = useState("");

	useEffect(() => {
		// Find the blog by title
		const foundPost = postsData.find((p) => titleToUrl(p.title) === title);
		setPost(foundPost);
		if (!foundPost) return;
		import(`../markdown/${foundPost.markdown}.md`)
			.then((res) => {
				fetch(res.default)
					.then((res) => res.text())
					.then((res) => setMarkdown(res))
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	}, [title]);

	if (!post) {
		return <div>Post not found</div>;
	}

	return (
		<Box>
			<Fade in={true}>
				<Flex
					direction="column"
					justify="center"
					align="center"
					px="10"
					pt="4"
					pb="10"
				>
					<Flex
						direction="column"
						justify="center"
						align="center"
						fontSize={{ md: "lg" }}
						textAlign="center"
						maxWidth={{ base: "100%", md: "50%" }}
					>
						<Heading size="xl" textAlign="center" p="5">
							{post.title}
						</Heading>
						<Text color="gray.500">{parseDate(post.date)}</Text>
						<Fade in={true}>
							<MarkdownComponent markdown={markdown} />
						</Fade>
					</Flex>
				</Flex>
			</Fade>
		</Box>
	);
};

export default BlogPost;
