import { Box, Heading, Flex, Text, Link, Fade, Icon } from "@chakra-ui/react";
import { MdBuild, MdLibraryBooks } from "react-icons/md";
import React from "react";
import posts from "../static/posts";
import { Link as RouteLink } from "react-router-dom";

function titleToUrl(title) {
	return title
		.toLowerCase()
		.trim()
		.replace(/\s+/g, "-")
		.replace(/[^\w\-]+/g, "")
		.replace(/\-\-+/g, "-");
}

const BlogItem = ({ completed, title }) => (
	<Flex direction="row" justify="center" align="center" fontSize="18">
		<Icon
			as={completed === "true" ? MdLibraryBooks : MdBuild}
			color={completed === "true" ? "teal.300" : "grey"}
		/>
		{completed === "true" ? (
			<Link
				as={completed === "true" ? RouteLink : ""}
				to={titleToUrl(title)}
				px="3"
				color={completed === "true" ? "black" : "grey"}
			>
				{title}
			</Link>
		) : (
			<Flex
				userSelect="none"
				styling=""
				px="3"
				color={completed === "true" ? "black" : "grey"}
			>
				{title}
			</Flex>
		)}
	</Flex>
);

export default function Blog() {
	const themeColor = "teal.100";
	return (
		<Box>
			<Fade in="true">
				<Flex
					direction="column"
					justify="start"
					align="center"
					minH={{ base: "300px", md: "600px" }}
				>
					<Flex
						minW="100%"
						direction="column"
						justify="start"
						align="center"
						py={10}
						minH={{ base: "300px", md: "400px" }}
					>
						<Heading
							fontSize={{ base: "3xl", md: "4xl" }}
							mb={6}
							width="100%"
							textAlign="center"
						>
							Blog
						</Heading>
						<Flex
							direction={{ base: "column", md: "row" }}
							justify="center"
							align="center"
							fontSize={{ md: "xl" }}
							color="grey"
							px={{ base: "3", md: "6" }}
							pb="5"
							textAlign="center"
							maxWidth={{ base: "90%", md: "70%" }}
						>
							Thoughts that often come to my mind, and sometimes make it onto
							the internet.
						</Flex>
						<Flex
							direction="column"
							justify="center"
							align="flex-start"
							gap="3"
							px="9"
						>
							{posts.map((post, index) => (
								<BlogItem key={index} {...post} />
							))}
						</Flex>
					</Flex>
				</Flex>
			</Fade>
		</Box>
	);
}
