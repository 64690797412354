import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Text,
	IconButton,
	Button,
	ButtonGroup,
	Stack,
	Collapse,
	Link,
	useBreakpointValue,
	Fade,
	Center,
	ScaleFade,
	Slide,
	SlideFade,
	Highlight,
	defineStyleConfig,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import cs_bro from "../static/cs-bro.svg";
import { FaBook, FaProjectDiagram, FaGithub } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { Link as RouteLink, useLocation, useNavigate } from "react-router-dom";

const TypingComponent = ({ setCurrState }) => {
	const introText = `<h1> Hey there! I'm Rishi. </h1>\n 
		<p> I craft elegant user-friendly applications that showcase the full power of modern web technologies. </p>`;
	return (
		<TypeAnimation
			sequence={[
				1000,
				() => setCurrState("unsaved"),
				`Hey there!`,
				() => setCurrState("saved"),
				2000,
				() => setCurrState("unsaved"),
				introText,
				1000,
				() => setCurrState("saved"),
				1000,
				() => setCurrState("rendering"),
				1000,
				() => setCurrState("rendered"),
			]}
			wrapper="span"
			cursor={false}
			repeat={0}
			style={{
				whiteSpace: "pre-line",
				fontSize: "1.8em",
				fontFamily: "monospace",
				display: "inline-block",
				textAlign: "left",
				width: "100%",
				height: "100%",
			}}
		/>
	);
};

const ActualComponent = () => {
	let themeColor = "teal.100";
	return (
		<Fade in="true">
			<Flex direction="column">
				<Flex direction={{ base: "column", md: "row" }}>
					<VStack
						justify="center"
						maxW={{ base: "100%", md: "60%" }}
						px={{ base: "0", md: "6" }}
					>
						<Heading fontSize={{ base: "2xl", md: "4xl" }} mb={4} width="100%">
							{" "}
							Hey there! I'm Rishi 👋{" "}
						</Heading>
						<VStack justify="center" maxW="100%" spacing="2">
							<Text
								fontSize={{ base: "xl", md: "2xl" }}
								color="RGBA(0, 0, 0, 0.75)"
								minW="100%"
							>
								I craft elegant user-friendly applications that showcase the
								full power of modern web technologies.
							</Text>
							<Text
								fontSize={{ base: "xl", md: "2xl" }}
								color="RGBA(0, 0, 0, 0.75)"
								minW="100%"
							>
								On the side, I love playing sports, cooking food, and learning
								new languages.
							</Text>
							<Text
								fontSize={{ base: "xl", md: "2xl" }}
								color="RGBA(0, 0, 0, 0.75)"
								minW="100%"
							>
								And this is my personal space on the internet.
							</Text>
						</VStack>
					</VStack>
					<Flex
						direction={{ base: "row" }}
						justify="center"
						maxW={{ base: "100%", md: "40%" }}
					>
						<Image
							src={cs_bro}
							alt="logo"
							boxSize={{ base: "200px", md: "500px" }}
						/>
					</Flex>
				</Flex>
				<Center>
					<Flex
						direction={{ base: "column", md: "row" }}
						minH={{ base: "200", md: "0" }}
						justify="space-evenly"
						minW="60%"
					>
						<Link as={RouteLink} to="/projects" _hover={{ cursor: "pointer" }}>
							<Button
								bg={themeColor}
								size="lg"
								leftIcon={<FaProjectDiagram />}
								minW="100%"
							>
								{" "}
								Projects{" "}
							</Button>
						</Link>
						<Link as={RouteLink} to="/blog" _hover={{ cursor: "pointer" }}>
							{" "}
							<Button
								bg={themeColor}
								size="lg"
								leftIcon={<FaBook />}
								minW="100%"
							>
								{" "}
								Blog{" "}
							</Button>{" "}
						</Link>
						<Link
							href="https://github.com/Rishi1204"
							isExternal
							_hover={{ cursor: "pointer" }}
						>
							<Button
								bg={themeColor}
								size="lg"
								leftIcon={<FaGithub />}
								minW="100%"
							>
								{" "}
								GitHub{" "}
							</Button>{" "}
						</Link>
					</Flex>
				</Center>
			</Flex>
		</Fade>
	);
};

const StateComponent = ({ currState }) => {
	if (currState === "unsaved") {
		return (
			<Fade in="true">
				<Text color="grey"> Unsaved changes </Text>
			</Fade>
		);
	} else if (currState === "saved") {
		return (
			<Fade in="true">
				<Text color="green"> All changes saved </Text>
			</Fade>
		);
	} else if (currState === "rendering") {
		return (
			<Button isLoading bg="teal.100" variant="solid">
				Email
			</Button>
		);
	} else if (currState === "rendered") {
		return (
			<Fade in="true">
				<Text color="grey"></Text>
			</Fade>
		);
	}
};

export default function Home({ startingState }) {
	const [currState, setCurrState] = useState(startingState);
	return (
		<Box>
			<Fade in={true}>
				<Flex
					py={{ base: 4 }}
					px={{ base: 4 }}
					direction={{ base: "column", md: "column" }}
					justify="center"
					align="center"
					minH={{ base: "300px", md: "600px" }}
				>
					<Flex
						py={{ base: 8 }}
						px={{ base: 2 }}
						direction={{ base: "column", md: "row" }}
						justify="flex-start"
						align="flex-start"
						w={{ base: "85%", md: "65%" }}
						minH={{ base: "400px", md: "250px" }}
					>
						{/* <TypingComponent setCurrState={setCurrState} /> */}
						{currState === "rendered" ? (
							<ActualComponent />
						) : (
							<TypingComponent setCurrState={setCurrState} />
						)}
					</Flex>
					<Flex
						py={{ base: 2 }}
						px={{ base: 2 }}
						direction={{ base: "row", md: "row" }}
						justify="flex-start"
						w={{ base: "85%", md: "65%" }}
						align="left"
					>
						<StateComponent currState={currState} />
					</Flex>
				</Flex>
			</Fade>
		</Box>
	);
}
