import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./components/Home";
import Projects from "./components/Projects";
import ProjectPage from "./components/ProjectPage";
import BlogPost from "./components/BlogPost";
import TreeVisualizer from "./components/TreeVisualizer";
import UnderConstructionComponent from "./components/UnderConstructionComponent";
import Blog from "./components/Blog";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar.js";
import "@fontsource/merriweather";
import "@fontsource/lora";
import "@fontsource/roboto-slab";

const customTheme = extendTheme({
	fonts: {
		heading: `'Lora', sans-serif`,
		body: `'merriweather', sans-serif`,
	},
	config: {
		initialColorMode: "light", // Set your preferred mode here
		useSystemColorMode: false, // Disables the use of system color mode
	},
	styles: {
		global: {
			"html, body": {
				overflowY: "scroll", // disable scroll vertical bar
				scrollbarWidth: "none",
				"::-webkit-scrollbar": {
					display: "none",
				},
			},
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<ChakraProvider theme={customTheme}>
			<Router>
				<NavBar />
				<Routes>
					<Route path="/" element={<Home startingState="unsaved" />} />
					<Route path="/home" element={<Home startingState="rendered" />} />
					<Route path="/projects" element={<Projects />} />
					<Route
						path="/blog"
						element={true ? <Blog /> : <UnderConstructionComponent />}
					/>
					<Route path="/projects/:title" element={<ProjectPage />} />
					<Route path="/blog/:title" element={<BlogPost />} />
					<Route path="/prereq-visualizer" element={<TreeVisualizer />} />
				</Routes>
			</Router>
		</ChakraProvider>
	</>
);
