import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Text,
	IconButton,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	Stack,
	Collapse,
	Link,
	useBreakpointValue,
	useDisclosure,
	Fade,
	Center,
	ScaleFade,
	Slide,
	SlideFade,
	Highlight,
	UnorderedList,
	Divider,
	ListItem,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import projectsData from "../static/projects";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownComponent = ({ markdown }) => (
	<Flex direction="column" justify="center" textAlign="left" minWidth="100%">
		<Markdown
			components={{
				h1: ({ node, ...props }) => (
					<Box>
						<Heading as="h1" size="lg" pt="4" pb="2" {...props} />
						<Divider borderColor="gray.300" />
						<Box py="2"></Box>
					</Box>
				),
				h2: ({ node, ...props }) => (
					<Box>
						<Heading as="h2" size="md" pt="4" pb="2" {...props} />
						<Divider colorScheme="messenger" />
						<Box py="2"></Box>
					</Box>
				),
				ul: ({ node, ...props }) => <UnorderedList spacing={1} {...props} />,
				li: ({ node, ...props }) => <ListItem {...props} />,
				a: ({ node, ...props }) => (
					<Link
						color="blue.500"
						rel="noopener noreferrer"
						target="_blank"
						{...props}
					/>
				),
				blockquote: ({ node, ...props }) => (
					<Flex
						direction="column"
						py={4}
						px={{ base: "10", md: "20" }}
						height="100%"
						color="gray.500"
					>
						<Divider borderColor="gray.300" orientation="horizontal" />
						<Box {...props} py="2" as="i" color="gray.500" textAlign="center" />
						<Divider borderColor="gray.300" orientation="horizontal" />
					</Flex>
				),
			}}
			remarkPlugins={[remarkGfm]}
		>
			{markdown}
		</Markdown>
	</Flex>
);

export default MarkdownComponent;
