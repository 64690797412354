import { React, useEffect, useRef } from 'react';
import { Flex, Text, Fade } from '@chakra-ui/react';
import * as d3 from 'd3';

const LINK_ENABLED = '#B3A369';
const LINK_DISABLED = '#C3C3C3';
const NODE_ACTIVE = '#B3A369';
const NODE_ENABLED = '#003057';
const NODE_DISABLED = '#C3C3C3';
const OPNODE_DISABLED = '#003057';
const OPNODE_FILL_DISABLED = 'white';

/**
 * Generates a course network graph based on the given nodes and links
 * @param {Array} nodes - the nodes for the graph
 * @param {Array} links - the edges for the graph
 */
function createGraph(nodes, links, treeCanvasRef) {
	if (
		treeCanvasRef.current === null ||
		nodes.length === 0 ||
		links.length == 0
	) {
		return;
	}
	// Clear canvas and add new groups
	treeCanvasRef.current.style.opacity = 0;
	var svg = d3.select(treeCanvasRef.current);
	svg.selectAll('*').remove();
	svg.append('g').attr('class', 'links');
	svg.append('g').attr('class', 'nodes');
	svg.append('g').attr('class', 'text');
	// console.log(nodes);
	const ROOT = nodes[0].name;
	var leafNodes = new Set();
	for (let i = 0; i < nodes.length; i++) {
		if (nodes[i].enabled === true) {
			leafNodes.add(nodes[i].id);
		}
	}
	/**
	 * Recursive function to disable nodes when clicked
	 * @param {Array} d - datum object for the node (and its links) to be disabled
	 */
	function disableAndDeactivate(d) {
		d.active = false;
		d3.select('.text #' + d.id)
			.style(
				'fill',
				d.name === 'or' || d.name === 'and'
					? OPNODE_DISABLED // if the node is an operator node
					: d.enabled
					? NODE_ENABLED // if the node is a leaf node
					: NODE_DISABLED
			)
			.style(
				'stroke',
				d.name === 'or' || d.name === 'and'
					? OPNODE_DISABLED
					: d.enabled
					? NODE_ENABLED
					: NODE_DISABLED
			);
		d3.select('.nodes #' + d.id)
			.style(
				'fill',
				d.name === 'or' || d.name === 'and'
					? OPNODE_FILL_DISABLED
					: d.enabled
					? NODE_ENABLED
					: NODE_DISABLED
			)
			.style(
				'stroke',
				d.name === 'or' || d.name === 'and'
					? OPNODE_DISABLED
					: d.enabled
					? NODE_ENABLED
					: NODE_DISABLED
			)
			.style(
				'cursor',
				d.name === 'or' || d.name === 'and'
					? 'default'
					: d.enabled
					? 'pointer'
					: 'default'
			);
		for (let i = 0; i < links.length; i++) {
			if (
				links[i].source.id === d.id &&
				links[i].source.parents.includes(links[i].target)
			) {
				let removeIndex = links[i].target.activeChildren.indexOf(
					links[i].source
				);
				links[i].target.activeChildren.splice(removeIndex, 1);
				links[i].target.enabled = false;
				if (leafNodes.has(links[i].target.id)) {
					links[i].target.enabled = true;
				}
				if (
					links[i].target.active === true &&
					links[i].target.name === 'or' &&
					links[i].target.activeChildren.length === 0
				) {
					disableAndDeactivate(links[i].target);
				} else if (
					links[i].target.active === true &&
					links[i].target.name === 'and' &&
					links[i].target.activeChildren.length <
						links[i].target.children.length
				) {
					disableAndDeactivate(links[i].target);
				} else if (
					links[i].target.name !== 'and' &&
					links[i].target.name !== 'or'
				) {
					disableAndDeactivate(links[i].target);
				}
				links[i].status = 'disabled';
				updateLinks();
			} else if (
				links[i].target.id === d.id &&
				links[i].target.parents.includes(links[i].source)
			) {
				links[i].source.enabled = false;
				let removeIndex = links[i].source.activeChildren.indexOf(
					links[i].target
				);
				links[i].source.activeChildren.splice(removeIndex, 1);
				if (leafNodes.has(links[i].source)) {
					links[i].source.enabled = true;
				}
				if (links[i].source.name !== 'and' && links[i].source.name !== 'or') {
					disableAndDeactivate(links[i].source);
				} else if (
					links[i].source.active === true &&
					links[i].source.name === 'and' &&
					links[i].source.activeChildren.length <
						links[i].source.children.length
				) {
					disableAndDeactivate(links[i].source);
				} else if (
					links[i].source.active === true &&
					links[i].source.name === 'or' &&
					links[i].source.activeChildren.length === 0
				) {
					disableAndDeactivate(links[i].source);
				}
				links[i].status = 'disabled';
				updateLinks();
			}
		}
	}
	/**
	 * Recursive function to enable nodes when clicked
	 * @param {Array} d - datum object for the node (and its links) to be enabled
	 */
	function enableAndActivate(d) {
		d.active = true;
		d3.select('.nodes #' + d.id)
			.style(
				'fill',
				d.name === 'or' || d.name === 'and' ? OPNODE_FILL_DISABLED : NODE_ACTIVE
			)
			.style('stroke', NODE_ACTIVE)
			.style(
				'cursor',
				d.name === 'or' || d.name === 'and' ? 'default' : 'pointer'
			);
		d3.select('.text #' + d.id)
			.style('fill', NODE_ACTIVE)
			.style('stroke', NODE_ACTIVE);
		for (let i = 0; i < links.length; i++) {
			if (
				links[i].source.id === d.id &&
				links[i].source.parents.includes(links[i].target)
			) {
				links[i].target.enabled = true;
				links[i].status = 'enabled';
				if (!links[i].target.activeChildren.includes(links[i].source)) {
					links[i].target.activeChildren.push(links[i].source);
				}
				if (
					links[i].target.activeChildren.length ===
						links[i].target.children.length &&
					links[i].target.name === 'and'
				) {
					enableAndActivate(links[i].target);
				} else if (
					links[i].target.activeChildren.length > 0 &&
					links[i].target.name === 'or'
				) {
					enableAndActivate(links[i].target);
				} else if (
					links[i].target.name !== 'or' &&
					links[i].target.name !== 'and'
				) {
					links[i].target.enabled = true;
					d3.select('.nodes #' + links[i].target.id)
						.style('fill', NODE_ENABLED)
						.style('stroke', NODE_ENABLED)
						.style('cursor', 'pointer');
					d3.select('.text #' + links[i].target.id)
						.style('fill', NODE_ENABLED)
						.style('stroke', NODE_ENABLED);
					// createAlert(
					// 	'You have unlocked ' + links[i].target.name + '.',
					// 	'success',
					// 	alertIndex
					// );
					// alertIndex++;
				}
				updateLinks();
			} else if (
				links[i].target.id === d.id &&
				links[i].target.parents.includes(links[i].source)
			) {
				links[i].source.enabled = true;
				links[i].status = 'enabled';
				if (!links[i].source.activeChildren.includes(links[i].target)) {
					links[i].source.activeChildren.push(links[i].target);
				}
				if (
					links[i].source.activeChildren.length ===
						links[i].source.children.length &&
					links[i].source.name === 'and'
				) {
					enableAndActivate(links[i].source);
				} else if (
					links[i].source.activeChildren.length > 0 &&
					links[i].source.name === 'or'
				) {
					enableAndActivate(links[i].source);
				} else if (
					links[i].source.name !== 'or' &&
					links[i].source.name !== 'and'
				) {
					links[i].source.enabled = true;
					d3.select('.nodes #' + links[i].source.id)
						.style('fill', NODE_ENABLED)
						.style('stroke', NODE_ENABLED)
						.style('cursor', 'pointer');
					d3.select('.text #' + links[i].source.id)
						.style('fill', NODE_ENABLED)
						.style('stroke', NODE_ENABLED);
					// createAlert(
					// 	'You have unlocked ' + links[i].source.name + '.',
					// 	'success',
					// 	alertIndex
					// );
					// alertIndex++;
				}
				updateLinks();
			}
		}
	}
	function updateNodes() {
		var circles = d3
			.select('.nodes')
			.selectAll('circle')
			.data(nodes)
			.join('circle')
			.attr('r', 10)
			.attr('cx', function (d) {
				return d.x;
			})
			.attr('cy', function (d) {
				return d.y;
			})
			.attr('id', function (d) {
				return d.id;
			})
			.attr('fill', function (d) {
				if (d.enabled === true && d.active === true) {
					return NODE_ENABLED;
				} else if (d.name === 'or' || d.name === 'and') {
					return OPNODE_FILL_DISABLED;
				} else if (d.enabled === true && d.active === false) {
					return NODE_ENABLED;
				} else {
					return NODE_DISABLED;
				}
			})
			.attr('stroke', function (d) {
				if (d.enabled === true && d.active === true) {
					return NODE_ENABLED;
				} else if (d.name === 'or' || d.name === 'and') {
					return OPNODE_DISABLED;
				} else if (d.enabled === true && d.active === false) {
					return NODE_ENABLED;
				} else {
					return NODE_DISABLED;
				}
			})
			.attr('mouseover', function (d) {
				if (d.name === 'or' || d.name === 'and') {
					d3.select(this).style('cursor', 'default');
				} else if (d.enabled === true) {
					d3.select(this).style('cursor', 'pointer');
				} else {
					d3.select(this).style('cursor', 'default');
				}
			})
			.attr('stroke-width', 4)
			.on('click', function (event, d) {
				if (
					d.enabled === true &&
					d.active === false &&
					d.name !== 'and' &&
					d.name !== 'or'
				) {
					enableAndActivate(d);
				} else if (
					d.enabled === true &&
					d.active === true &&
					d.name !== 'and' &&
					d.name !== 'or'
				) {
					disableAndDeactivate(d);
				}
			});
		var labels = d3
			.select('.text')
			.selectAll('text')
			.data(nodes)
			.join('text')
			.text(function (d) {
				return d.name;
			})
			.attr('stroke', function (d) {
				if (d.enabled === true && d.active === true) {
					return NODE_ENABLED;
				} else if (d.name === 'or' || d.name === 'and') {
					return OPNODE_DISABLED;
				} else if (d.enabled === true && d.active === false) {
					return NODE_ENABLED;
				} else {
					return NODE_DISABLED;
				}
			})
			.attr('fill', function (d) {
				if (d.enabled === true && d.active === true) {
					return NODE_ENABLED;
				} else if (d.name === 'or' || d.name === 'and') {
					return OPNODE_DISABLED;
				} else if (d.enabled === true && d.active === false) {
					return NODE_ENABLED;
				} else {
					return NODE_DISABLED;
				}
			})
			.attr('id', function (d) {
				return d.id;
			})
			.attr('text-anchor', 'middle')
			.attr('x', function (d) {
				return d.x;
			})
			.attr('y', function (d, index) {
				return index == 0 ? d.y - 20 : d.y + 30;
			});
	}
	function updateLinks() {
		var u = d3
			.select('.links')
			.selectAll('line')
			.data(links)
			.join('line')
			.attr('id', function (d, index) {
				return 'link-' + index;
			})
			.attr('x1', function (d) {
				return d.source.x;
			})
			.attr('y1', function (d) {
				return d.source.y;
			})
			.attr('x2', function (d) {
				return d.target.x;
			})
			.attr('y2', function (d) {
				return d.target.y;
			})
			.attr('stroke', function (d) {
				if (d.status === 'disabled') {
					return LINK_DISABLED;
				} else {
					return LINK_ENABLED;
				}
			})
			.attr('stroke-width', 2);
	}
	updateNodes();
	updateLinks();
}

/**
 * Replaces the indices in the links array with the actual node objects
 * @param {Array} links - the links for the graph
 * @param {Array} nodes - the nodes for the graph
 */
function processLinks(links, nodes, treeCanvasRef) {
	if (
		treeCanvasRef.current === null ||
		links.length === 0 ||
		nodes.length === 0
	) {
		return;
	}
	// console.log(links);
	links.forEach((l) => {
		l.source = nodes[l.source];
		l.target = nodes[l.target];
		l.source.children.push(l.target);
		l.target.parents.push(l.source);
	});
}

/**
 * Positions the nodes in the graph
 * @param {Array} nodes - the nodes for the graph
 */
function positionNodes(nodes, treeCanvasRef) {
	if (treeCanvasRef.current === null) {
		return;
	}
	var width = treeCanvasRef.current.clientWidth,
		height = treeCanvasRef.current.clientHeight,
		rootX = width / 2,
		rootY = height / 6;
	nodes.forEach((node) => {
		if (node.parents.length === 0) {
			node.x = rootX;
			node.y = rootY;
			node.level = 0;
			node.angle = 0;
			return;
		}
		const parent = node.parents[0];
		node.level = parent.level + 1;
		const numSiblings = parent.children.length;
		const siblingIndex = parent.children.indexOf(node);
		const radius = Math.max(300 - 70 * node.level, 140);
		const factor = -6 / 8;
		const angle = ((1 - factor) * Math.PI) / (numSiblings + 1);
		const startAngle = -(factor / 2) * Math.PI;
		const finalAngle = startAngle - (siblingIndex + 1) * angle + parent.angle;
		node.x = parent.x + radius * Math.cos(finalAngle);
		node.y = parent.y - radius * Math.sin(finalAngle);
		node.angle = finalAngle + Math.PI / 2;
	});
}

function createAlert(message, type, index) {
	var alertBar = document.getElementById('alert-bar');
	var newAlert = document.createElement('div');
	newAlert.id = 'alert-' + index;
	newAlert.className = 'alert alert-' + type;
	newAlert.role = 'alert';
	newAlert.innerText = message;
	alertBar.appendChild(newAlert);
	if (type !== 'success') {
		const xMax = 16;
		anime({
			targets: '#search-bar',
			easing: 'easeInOutSine',
			duration: 550,
			translateX: [
				{
					value: xMax * -1,
				},
				{
					value: xMax,
				},
				{
					value: xMax / -2,
				},
				{
					value: xMax / 2,
				},
				{
					value: 0,
				},
			],
		});
	}
	setTimeout(() => {
		removeAlert(index);
	}, 250);
}

function removeAlert(index) {
	setTimeout(() => {
		var alertBar = document.getElementById('alert-bar');
		alertBar.removeChild(document.getElementById('alert-' + index));
	}, 2000);
}

export function CourseTree({ courseName, nodes, links }) {
	const treeCanvasRef = useRef(null);
	useEffect(() => {
		processLinks(links, nodes, treeCanvasRef);
		positionNodes(nodes, treeCanvasRef);
		createGraph(nodes, links, treeCanvasRef);
		treeCanvasRef.current.style.opacity = 1;
	}, [links, nodes]);
	return (
		<Flex
			direction="column"
			align="center"
			justify="center"
			py={{ base: '4', md: '6' }}
			minW="100%"
			minH="100vh"
		>
			<svg id="tree-canvas" ref={treeCanvasRef}></svg>
		</Flex>
	);
}
