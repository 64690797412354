import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Text,
	IconButton,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	Stack,
	Collapse,
	Link,
	useBreakpointValue,
	useDisclosure,
	Fade,
	Center,
	ScaleFade,
	Slide,
	SlideFade,
	Highlight,
	Skeleton,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import projects from '../static/projects';
import { TypeAnimation } from 'react-type-animation';
import { Link as RouteLink } from 'react-router-dom';

function titleToUrl(title) {
	return title
		.toLowerCase() // Convert to lowercase
		.trim() // Remove leading and trailing spaces
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-'); // Replace multiple hyphens with a single hyphen
}

const ProjectCard = ({ image, title, description }) => {
	const themeColor = 'teal.100';
	return (
		<Flex
			direction={{ base: 'column', md: 'row' }}
			justify="center"
			align="center"
			fontSize={{ md: 'xl' }}
			color="grey"
			p={{ base: '3', md: '6' }}
			textAlign="center"
		>
			<Card
				direction={{ base: 'column', sm: 'row' }}
				overflow="hidden"
				variant="outline"
				maxWidth={{ base: '90%', md: '80%' }}
			>
				<Image
					objectFit="cover"
					maxW={{ base: '100%', sm: '200px' }}
					src={image ? image : 'https://bit.ly/2k1H1t6'}
					alt="Caffe Latte"
				/>

				<Stack>
					<CardBody>
						<Heading size="md" textAlign={{ md: 'left' }}>
							{title}
						</Heading>

						<Text
							py="2"
							fontSize={{ md: 'md' }}
							textAlign={{ md: 'left' }}
							color="grey"
						>
							{description}
						</Text>
					</CardBody>
					<CardFooter
						pt="0"
						direction={{ base: 'column', md: 'row' }}
						justify={{ base: 'center', md: 'right' }}
						align="right"
					>
						<Link
							as={RouteLink}
							to={titleToUrl(title)}
							_hover={{ cursor: 'pointer' }}
						>
							<Button variant="solid" bg={themeColor} fontSize="17">
								Read More
							</Button>
						</Link>
					</CardFooter>
				</Stack>
			</Card>
		</Flex>
	);
};

export default function Projects() {
	const themeColor = 'teal.100';
	return (
		<Box>
			<Fade in="true" duration={4}>
				<Flex
					direction="column"
					justify="start"
					align="center"
					minH={{ base: '300px', md: '600px' }}
				>
					<Flex
						direction="column"
						justify="start"
						align="center"
						py={10}
						minH={{ base: '300px', md: '400px' }}
					>
						<Heading
							fontSize={{ base: '3xl', md: '4xl' }}
							mb={6}
							width="100%"
							textAlign="center"
						>
							Projects
						</Heading>
						<Flex
							direction={{ base: 'column', md: 'row' }}
							justify="center"
							align="center"
							fontSize={{ md: 'xl' }}
							color="grey"
							px={{ base: '3', md: '6' }}
							pb="3"
							textAlign="center"
							maxWidth={{ base: '90%', md: '70%' }}
						>
							Here's a bunch of things that I've been inspired to create over
							the past few years, what they do, and my motivations behind them.
						</Flex>
						<Flex
							direction="column"
							justify="center"
							align="center"
							textAlign="center"
							maxWidth={{ base: '90%', md: '70%' }}
						>
							<Fade in="true">
								{projects.map((project, index) => (
									<ProjectCard
										key={index}
										image={project.image}
										title={project.title}
										description={project.description}
									/>
								))}
							</Fade>
						</Flex>
					</Flex>
				</Flex>
			</Fade>
		</Box>
	);
}
