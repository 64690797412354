import {
	Image,
	Box,
	Heading,
	Flex,
	VStack,
	Text,
	IconButton,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	Stack,
	Collapse,
	Link,
	useBreakpointValue,
	useDisclosure,
	Fade,
	Center,
	ScaleFade,
	Slide,
	SlideFade,
	Highlight,
	UnorderedList,
	Divider,
	ListItem,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import projectsData from "../static/projects";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MarkdownComponent from "./MarkdownComponent";

function titleToUrl(title) {
	return title
		.toLowerCase() // Convert to lowercase
		.trim() // Remove leading and trailing spaces
		.replace(/\s+/g, "-") // Replace spaces with hyphens
		.replace(/[^\w\-]+/g, "") // Remove all non-word characters
		.replace(/\-\-+/g, "-"); // Replace multiple hyphens with a single hyphen
}

const ProjectPage = () => {
	const [project, setProject] = useState(null);
	const { title } = useParams(); // This captures the :title part of the URL
	const [markdown, setMarkdown] = useState("");
	const content = `# Hello, *world*!`;

	useEffect(() => {
		// Find the project by title
		// Note: You might need to format the title to match your data structure
		const foundProject = projectsData.find(
			(p) => titleToUrl(p.title) === title
		);
		setProject(foundProject);
		if (!foundProject) return;
		import(`../markdown/${foundProject.markdown}.md`)
			.then((res) => {
				fetch(res.default)
					.then((res) => res.text())
					.then((res) => setMarkdown(res))
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	}, [title]);

	if (!project) {
		return <div>Project not found</div>;
	}

	return (
		<Box>
			<Fade in={true}>
				<Flex
					direction="column"
					justify="center"
					align="center"
					px="10"
					pt="4"
					pb="10"
				>
					<Flex
						direction="column"
						justify="center"
						align="center"
						fontSize={{ md: "lg" }}
						textAlign="center"
						maxWidth={{ base: "100%", md: "50%" }}
					>
						<Text
							pt="2"
							pb="0"
							fontSize={{ md: "md" }}
							textAlign={{ md: "right" }}
							minWidth={{ base: "100%", md: "100%" }}
							color="grey"
						>
							{project.date}
						</Text>
						<Heading size="xl" textAlign="center" p="5">
							{project.title}
						</Heading>
						<Text
							pb="2"
							fontSize={{ md: "lg" }}
							textAlign={{ md: "center" }}
							color="grey"
						>
							{project.description}
						</Text>
						<Image
							objectFit="cover"
							maxW={{ base: "100%", sm: "200px", md: "400px" }}
							borderRadius="md"
							src={project.image ? project.image : "https://bit.ly/2k1H1t6"}
							alt={project.description}
							p="5"
						/>
						<Fade in={true}>
							<MarkdownComponent markdown={markdown} />
						</Fade>
					</Flex>
				</Flex>
			</Fade>
		</Box>
	);
};

export default ProjectPage;
